import React from 'react'
import { ComponentRichText } from 'composable/components/cms-components/rich-text'
import { UiContainer } from 'composable/components/ui-container'

type RichTextTasticProps = {
  data: {
    markdown: string
    containerSize?: string
    containerMarginBottom?: string
    containerMarginTop?: string
  }
}

const RichTextTastic: React.FC<RichTextTasticProps> = ({ data }) => {
  const { markdown, containerSize, containerMarginTop, containerMarginBottom } = data
  if (!markdown) return null

  return (
    <UiContainer size={containerSize} marginTop={containerMarginTop} marginBottom={containerMarginBottom}>
      <ComponentRichText>{markdown}</ComponentRichText>
    </UiContainer>
  )
}

export default RichTextTastic
